/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  /* Regular */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  }

  /* Medium */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Poppins-Medium.ttf") format("truetype");
  }

  /* SemiBold */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  }

  /* Bold */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  }

  /* Italic Regular */
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Poppins-Italic.ttf") format("truetype");
  }

  /* Italic Medium */
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Poppins-MediumItalic.ttf") format("truetype");
  }

  /* Italic SemiBold */
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  }

  /* Italic Bold */
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Poppins-BoldItalic.ttf") format("truetype");
  }

  /* Light */
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/Poppins-Light.ttf") format("truetype");
  }

  /* Italic Light */
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/Poppins-LightItalic.ttf") format("truetype");
  }
  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

html,
body,
span,
p {
  @apply font-sans !important;
}
